import { configureStore } from "@reduxjs/toolkit"

import membershipsReducer from "./slices/membershipsSlice"
import userReducer from "./slices/userSlice"
import tasksReducer from "./slices/tasksSlice"

export const store = configureStore({
  reducer: {
    memberships: membershipsReducer,
    user: userReducer,
    tasks: tasksReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
