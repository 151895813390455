import { createSlice, Slice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

type MembershipDetailsPayload = {
  id: number
  role: string
  organizationId: number
}

export interface MembershipState {
  activeMembershipId: number
  activeOrganizationId: number
}

const initialState: MembershipState = {
  activeMembershipId: -1,
  activeOrganizationId: -1,
}

export const membershipsSlice = createSlice({
  name: "memberships",
  initialState,
  reducers: {
    setMembershipDetails: (state, action: PayloadAction<MembershipDetailsPayload>): void => {
      const { id, organizationId } = action.payload

      state.activeMembershipId = id
      state.activeOrganizationId = organizationId
    },
  },
}) as Slice<MembershipState>

export const { setMembershipDetails } = membershipsSlice.actions
export const selectMembershipDetails = (state: { memberships: MembershipState }) => state.memberships
export const selectMembershipOrgId = (state: { memberships: MembershipState }) => state.memberships.activeOrganizationId
export const selectMembershipId = (state: { memberships: MembershipState }) => state.memberships.activeMembershipId

export default membershipsSlice.reducer
